import axios from "axios";
import store from "@/store";
import { router } from "@/router";

const instance = axios.create({
	// 开发者模式下使用本地服务器
	// baseURL: "https://dcat.tkcharity.top", //泰国
	// baseURL: "https://dcat.tkcharity.online", //越南
	baseURL:
		process.env.VUE_APP_LANG === "vi_VN"
			? "https://dcat.tkcharity.online"
			: "https://dcat.tkcharity.top",
	timeout: 1000 * 10,
});

// 添加请求拦截器
instance.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		config.headers["Authorization"] = "Bearer" + " " + store.state.token;
		config.headers["Accept-Language"] = store.state.lang;
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
instance.interceptors.response.use(
	function (response) {
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		return response.data;
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		console.log(error);
		if (error.response.status === 401) {
			store.commit("logout");
			router.push("/login");
		}
		return Promise.reject(error);
	}
);

const post = (url, data, config) => {
	return instance.post(url, data, config);
};

const get = (url, config) => {
	return instance.get(url, config);
};

const upload = (url, formData, config) => {
	return instance.post(url, formData, config);
};

export { post, get, upload };
