export const IMAGE_URL =
	process.env.VUE_APP_LANG === "vi_VN"
		? "https://dcat.tkcharity.online/storage/"
		: "https://dcat.tkcharity.top/storage/";
// export const PUSHER_APP_KEY = 'GEQTrDY0bnIXHBFC8ZofP9WVvUi7RAL1';//泰国
// export const PUSHER_APP_KEY = "oj3zRxwGkcIaLFEKPrQ5mpCYl1dAhOT2"; //越南
export const PUSHER_APP_KEY =
	process.env.VUE_APP_LANG === "vi_VN"
		? "oj3zRxwGkcIaLFEKPrQ5mpCYl1dAhOT2"
		: "GEQTrDY0bnIXHBFC8ZofP9WVvUi7RAL1";
export const PUSHER_APP_CLUSTER = "mt1";
export const PUSHER_HOST =
	process.env.VUE_APP_LANG === "vi_VN"
		? "dcat.tkcharity.online"
		: "dcat.tkcharity.top";
export const PUSHER_PORT = "6001";
export const PUSHER_SCHEME = "https";
